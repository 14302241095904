<template>
  <!-- BEGIN: Content-->
  <div class="app-content content font">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row"></div>
      <div class="content-body">
        <!-- Dashboard Ecommerce Starts -->
        <section id="dashboard-ecommerce">
          <div class="row match-height" style="margin: 5px 5px 5px 5px">
        <!-- Medal Card -->
        <div class="col-xl-4 col-md-4 col-12" style="padding: 5px 5px 5px 5px">
              <div class="card custom-card card-congratulation-medal">
                <div class="card-body font">
                  <h5 style="color: #00364f">
                    <strong>Congratulations</strong> <br />
                    {{ userInfo.name }} <br />
                  </h5>
                  <p class="card-text font-small-3">Your Earning</p>
                  <h1 class="mb-75 mt-2 pt-50 font">
                    <a href="#" style="color: #f21300"
                      ><strong>₹ {{ wallet.balanceAmount }}</strong></a
                    >
                  </h1>

                  <img
                    src="/vimal-assets/app-assets/images/illustration/badge.svg"
                    class="congratulation-medal"
                    alt="Medal Pic"
                  />
                </div>
              </div>
            </div>
            <!--/ Medal Card -->

            <!-- Statistics Card -->
            <div class="col-xl-8 col-md-6 col-12"  style="padding: 5px 5px 5px 5px">
              <div class="card custom-card card-statistics">
                <div class="card-header">
                  <h4 class="card-title">Statistics</h4>
                  <div class="d-flex align-items-center">
                    <p class="card-text font-small-2 me-25 mb-0">Updated 1 month ago</p>
                  </div>
                </div>
                <div class="card-body statistics-body">
                  <div class="row">
                    <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                      <div class="d-flex flex-row">
                        <div class="avatar bg-light-primary me-2">
                          <div class="avatar-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-trending-up avatar-icon"
                            >
                              <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                              <polyline points="17 6 23 6 23 12"></polyline>
                            </svg>
                          </div>
                        </div>
                        <div class="my-auto">
                          <h4 class="fw-bolder mb-0">{{ dashboard.service }}</h4>
                          <p class="card-text font-small-3 mb-0">Services</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                      <div class="d-flex flex-row">
                        <div class="avatar bg-light-info me-2">
                          <div class="avatar-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-user avatar-icon"
                            >
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                          </div>
                        </div>
                        <div class="my-auto">
                          <h4 class="fw-bolder mb-0">
                            {{ dashboard.clientServiceAssigned }}
                          </h4>
                          <p class="card-text font-small-3 mb-0">Assign Projects</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-4 col-sm-6 col-12">
                      <div class="d-flex flex-row">
                        <div class="avatar bg-light-success me-2">
                          <div class="avatar-content" style="font-size: 16pt">₹</div>
                        </div>
                        <div class="my-auto">
                          <h4 class="fw-bolder mb-0">
                            {{ dashboard.clientServiceChallan }}
                          </h4>
                          <p class="card-text font-small-3 mb-0">Challan Amount</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Statistics Card -->
          </div>

          <div class="row" style="margin: -32px 5px 5px 5px">
            <div
              class="col-md-3 col-xl-3 col-lg-3 col-6"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card custom-card">
                <div class="card-body"  style="padding: 0.5rem 0.5rem 0.5rem 1rem">
                  <div class="d-flex">
                    <div class="font-heading">
                      <font-awesome-icon icon="edit" />
                    </div>
                    &nbsp;&nbsp;
                    <div class="row">
                      <div class="col-12">
                        <h1>
                          <strong style="color: #f21000">
                            {{ dashboard.clientServiceTotal }}</strong
                          >
                        </h1>
                      </div>
                      <div class="col-12">
                        <strong class="text">Projects</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-xl-3 col-lg-3 col-6"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card custom-card">
                <div class="card-body"  style="padding: 0.5rem 0.5rem 0.5rem 1rem">
                  <div class="d-flex">
                    <div class="font-heading">
                      <font-awesome-icon icon="clock" />
                    </div>
                    &nbsp;&nbsp;
                    <div class="row">
                      <div class="col-12">
                        <h1>
                          <strong style="color: #f21000"
                            ><strong style="color: #f21000">{{
                              dashboard.clientServiceAssigned +
                              dashboard.clientServiceProcessed +
                              dashboard.clientServiceReject +
                              dashboard.clientServicePending +
                              dashboard.clientServiceFinalize +
                              dashboard.clientServiceHold +
                              dashboard.clientServiceUpgrade
                            }}</strong></strong
                          >
                        </h1>
                      </div>
                      <div class="col-12">
                        <strong class="text">In Process</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-md-3 col-xl-3 col-lg-3 col-6"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card custom-card mt-top-numbers">
                <div class="card-body"  style="padding: 0.5rem 0.5rem 0.5rem 1rem">
                  <div class="d-flex">
                    <div class="font-heading">
                      <font-awesome-icon icon="exclamation-circle" />
                    </div>
                    &nbsp;&nbsp;
                    <div class="row">
                      <div class="col-12">
                        <h1>
                          <strong style="color: #f21000">
                            {{ dashboard.clientServiceCancelled }}</strong
                          >
                        </h1>
                      </div>
                      <div class="col-12">
                        <strong class="text">Cancelled</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-md-3 col-xl-3 col-lg-3 col-6"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card custom-card mt-top-numbers">
                <div class="card-body"  style="padding: 0.5rem 0.5rem 0.5rem 1rem">
                  <div class="d-flex">
                    <div class="font-heading">
                      <font-awesome-icon icon="check-double" />
                    </div>
                    &nbsp;&nbsp;
                    <div class="row">
                      <div class="col-12">
                        <h1>
                          <strong style="color: #f21000">
                            {{ dashboard.clientServiceCompleted }}</strong
                          >
                        </h1>
                      </div>
                      <div class="col-12">
                        <strong class="text">Completed</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row match-height" style="margin: -32px 5px 5px 5px">
            <div class="col-lg-4 col-12" style="padding: 5px 5px 5px 5px">
              <div class="card custom-card card-transaction">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 text-truncate">
                      <h4><strong>Services</strong></h4>
                    </div>
                    <div class="col-4 text-end">
                      <router-link
                        to="/professional/professional-services"
                        type="button"
                        class="btn btn-sm my-btn"
                      >
                        <small style="color: black"> View All &nbsp;&nbsp;</small>
                        <img
                          style="width: 10px"
                          src="/assets/image/right-arrow.png"
                          alt=""
                        />
                      </router-link>
                    </div>
                  </div>

                  <hr />
                  <div
                    class="transaction-item"
                    style="
                      border-bottom: 1px solid #ededed;
                      padding-bottom: 5px;
                      margin-top: -10px;
                    "
                    v-for="(service, index) in adminServices"
                    :key="index"
                  >
                    <div class="d-flex">
                      <div class="avatar rounded float-start" style="background: white">
                        <div class="avatar-content">
                          <img
                              style="width: 30px; height: 30px"
                              :src="profilePhotoFinder(service, 'service')"
                              alt=""
                            />
                        </div>
                      </div>
                      <div class="transaction-percentage" style="line-height: 1">
                        <h6 class="transaction-title">
                          <strong v-if="service.service" style="color: #00364f">
                            {{ service.service.name }}
                          </strong>
                        </h6>
                        <small v-if="service.service"> {{ service.service.code }}</small>
                      </div>
                    </div>
                    <div v-if="service.service">
                    
                    </div>
                    <p
                                v-if="service.service.status == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  box-shadow: 2px 2px 5px gray;
                                  width: 70px;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> Active</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  box-shadow: 2px 2px 5px gray;
                                  width: 70px;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> InActive</b>
                              </p>
                    <!-- <div v-else class="text-danger">InActive</div> -->
                  </div>
                </div>
              </div>
            </div>

            <!-- Plateform Admins Card -->
            <div class="col-lg-4 col-12" style="padding: 5px 5px 5px 5px">
              <div class="card custom-card card-transaction">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 text-truncate">
                      <h4><strong>Tickets</strong></h4>
                    </div>
                    <div class="col-4 text-end">
                      <router-link
                        to="/professional/ticket"
                        type="button"
                        class="btn btn-sm my-btn"
                      >
                        <small style="color: black"> View All &nbsp;&nbsp;</small>
                        <img
                          style="width: 10px"
                          src="/assets/image/right-arrow.png"
                          alt=""
                        />
                      </router-link>
                    </div>
                  </div>

                  <hr />
                  <div
                    class="transaction-item"
                    v-for="(ticket, index) in tickets"
                    :key="index"
                  >
                    <div class="d-flex">
                      <div class="transaction-percentage">
                        <h6 class="transaction-title" style="padding-bottom: 10px">
                          <strong v-if="ticket.department" style="color: #00364f">{{
                            ticket.department.name
                          }}</strong>
                        </h6>

                        <small
                          >{{ ticket.ticketNo }} ({{
                            dateTime(ticket.updated_at)
                          }}
                          )</small
                        >
                      </div>
                    </div>
                    <div>
                      <p
                        v-if="ticket.status == 'open'"
                        class="btn btn-sm"
                        data-v-4a972433=""
                        style="
                          width: 60px;
                          background-color: green;
                          color: white;
                          box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px 0px;
                          border-radius: 50px;
                          font-size: 10px;
                          padding: 3px;
                          margin-top: 10px;
                        "
                      >
                        <b data-v-4a972433="">open</b>
                      </p>
                      <p
                        v-else
                        class="btn btn-sm"
                        data-v-4a972433=""
                        style="
                          width: 60px;
                          background-color: #f21000;
                          color: white;
                          box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px 0px;
                          border-radius: 50px;
                          font-size: 10px;
                          padding: 3px;
                          margin-top: 10px;
                        "
                      >
                        <b data-v-4a972433="">close</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Plateform Admins Card -->

            <!-- Plateform Admins Card -->
            <div class="col-lg-4 col-12" style="padding: 5px 5px 5px 5px">
              <div class="card custom-card card-company-table">
                <div class="card-body p-0">
                  <div class="row pt-2 p-1">
                    <div class="col-8 text-truncate">
                      <h4><strong>Challans</strong></h4>
                    </div>
                    <div class="col-4 text-end">
                      <router-link
                        to="/professional/reimbursements"
                        type="button"
                        class="btn btn-sm my-btn"
                      >
                        <small style="color: black"> View All &nbsp;&nbsp;</small>
                        <img
                          style="width: 10px"
                          src="/assets/image/right-arrow.png"
                          alt=""
                        />
                      </router-link>
                    </div>
                  </div>

                  <div
                    class="container-fluid table-scroll"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <table class="table table-hover table-sm" style="color: #00364f">
                      <tr
                        class="text tr-head table-wrapper-scroll-y rounded-circle"
                        style="background-color: white; color: #0634f"
                      >
                        <th class="text-truncate" style="background-color: #cdcdcd">
                          Client
                        </th>

                        <th
                          class="text-truncate text-end"
                          style="background-color: #cdcdcd"
                        >
                          Challan
                        </th>
                      </tr>
                      <tbody class="text">
                        <tr v-for="(clientinfo, index) in invoices" :key="index">
                          <td class="text-truncate" v-if="clientinfo.client">
                            <div>
                              <strong>
                                {{ clientinfo.client.fname }}
                                {{ clientinfo.client.lname }}</strong
                              >
                            </div>
                            <small> {{ clientinfo.clientServiceNo }}</small>
                            <small>( {{ dateTime(clientinfo.date) }} )</small>
                            <div>
                              <small v-if="clientinfo.service"
                                ><strong> {{ clientinfo.service.name }}</strong></small
                              >
                            </div>
                          </td>

                          <td class="text-truncate text-end" v-if="clientinfo">
                            <div>
                              <span v-if="clientinfo.professionalChallans_Amt">
                                Rs. {{ clientinfo.professionalChallans_Amt }}
                              </span>
                            </div>
                            <p
                              v-if="clientinfo.professionalChallans_Status == 'pending'"
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: gray;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                                margin-top: 10px;
                              "
                            >
                              <b>pending</b>
                            </p>

                            <p
                              v-if="clientinfo.status == 'paid'"
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: #3ab04b;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                                margin-top: 10px;
                              "
                            >
                              <b>{{ clientinfo.status }}</b>
                            </p>

                            <p
                              v-if="clientinfo.status == 'rejected'"
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: black;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                                margin-top: 10px;
                              "
                            >
                              <b>{{ clientinfo.status }}</b>
                            </p>
                          </td>
                          <!-- <td class="text-truncate text-end">
                            <div
                              class="btn-group btn-group-sm"
                              role="group"
                              aria-label="..."
                            >
                              <router-link
                                :to="`/professional/project-view/${clientinfo.id}`"
                                class="btn btns btn-sm text-white"
                                style="
                                  background-color: #00364f;
                                  padding: 5px 4.5px 5px 4.5px;
                                "
                              >
                                <font-awesome-icon icon="eye" />
                              </router-link>
                            </div>
                          </td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Plateform Admins Card -->
          </div>

          <!-- start new -->
        </section>
        <!-- Dashboard Ecommerce ends -->
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import moment from "moment";
var _ = require("lodash");
export default {
  name: "SuperAdminHome",
  data() {
    return {
      adminServices: [],
      usersCount: {},
      wallet: {},
      userInfo: {},
      plateformAdmins: [],
      frenchformAdmins: [],
      tickets: [],
      withdrawls: [],
      invoices: [],
      masterDistributers: [],
      distributers: [],
      departMents: [],
      retailers: [],
      challans: [],
      dashboard: {},
    };
  },
  methods: {
    loadDashboard() {
      this.loading = true;
      this.$axios
        .get(`professional/dashboard`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.dashboard = res.data;
        })
        .finally(() => (this.loading = false));
    },

    findDepartment(depart_id) {
      var data = this.departMents.find((o) => o.id == depart_id);
      if (data) {
        return data.name;
      } else {
        return "NA";
      }
    },
    loadAdminServices() {
      this.loading = true;
      this.$axios
        .get(`professional/professionalservice?per_page=5`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.adminServices = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    loadWallet() {
      this.loading = true;
      this.$axios
        .get(
          `professional/wallet`,

          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          console.log(res);
          this.wallet = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    usersCountLoad() {
      this.loading = true;
      this.$axios
        .post(
          `admin/user/count`,
          {},
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          console.log(res);
          this.usersCount = res.data;
        })
        .finally(() => (this.loading = false));
    },
    adminlogofinder(admin) {
      var photo = "";
      if (admin.logo != null) {
        photo = this.$store.state.imgUrl + "/admin/" + admin.id + "/thumbs/" + admin.logo;
        return photo;
      } else {
        photo = this.$store.state.placeholderImg;
        return photo;
      }
    },
    loadTickets() {
      this.loading = true;
      this.$axios
        .get(`professional/clientserviceticket?per_page=5`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.tickets = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadInvoices() {
      this.loading = true;
      this.$axios
        .get(`professional/clientservice?per_page=5`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.invoices = res.data.data.data;
          this.loadChallans();
        })
        .finally(() => (this.loading = false));
    },

    loadChallans() {
      this.loading = true;
      this.$axios
        .get(`professional/clientservicechallan?per_page=5`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.challans = res.data.data.data;

          var challansData = this.challans;
          var projects = this.invoices;
          _.forEach(challansData, function (value) {
            if (
              _.find(projects, function (o) {
                return o.id == value.client_service_id;
              })
            ) {
              let index = _.findIndex(
                projects,
                (e) => {
                  return e.id == value.client_service_id;
                },
                0
              );

              var num = parseInt(value.amt);
              if (projects[index].professionalChallans_Amt) {
                num += parseInt(projects[index].professionalChallans_Amt);
                projects[index].professionalChallans_Amt = num;
              } else {
                projects[index].professionalChallans_Amt = value.amt;
              }
              projects[index].professionalChallans_Status = value.status;
            }
          });
          this.invoices = projects;
        })
        .finally(() => (this.loading = false));
    },
 
    loadDistributer() {
      this.loading = true;
      this.$axios
        .get(`/admin/distributer?per_page=5`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.distributers = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadClients() {
      this.loading = true;
      this.$axios
        .get(`retailer/client?per_page=5`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.retailers = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    profilePhotoFinder(masterDistributer, type) {
      var photo;
      if (masterDistributer.photo != null) {
        photo =
          this.$store.state.imgUrl +
          "/" +
          type +
          "/" +
          masterDistributer.id +
          "/thumbs/" +
          masterDistributer.photo;
        return photo;
      } else {
        photo = this.$store.state.placeholderImg;
        return photo;
      }
    },
    loadPageTitle(){
        document.title =this.$store.state.professionalPageTitles.dashboard
      }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadTickets();
      this.loadDistributer();
      this.loadClients();
      this.loadInvoices();
      this.loadWallet();
      this.loadAdminServices();
     
      this.loadDashboard();
      

      this.userInfo = JSON.parse(localStorage.getItem("userData"));
      if(localStorage.getItem("winLoad") == 'true'){
        localStorage.setItem("winLoad", 'false');
        window.location.reload();
      }

      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.font {
  font-family: "Poppins", sans-serif;
}

.bg-1-project {
}

/* .bg-1-admin {
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(0, 54, 79, 1) 100%);
} */

/* .bg-2-admin {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(242, 16, 0, 1) 100%
  );
} */

.bg-light-success {
  background: white !important;
}
.my-btn {
  color: black;
  transition: 0.5s;
  margin-right: -20px;
}
.my-btn:hover {
  background: rgba(212, 206, 206, 0.438);
}

.table-scroll {
  overflow-y: auto;
}

.form-select {
  border: 1px solid #00364f;
}

.form-checkbox {
  width: 25px;
  margin-top: 5px;
}

nav ul {
  height: 300px;
  width: 100%;
}

nav ul {
  overflow: hidden;
  overflow-y: scroll;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  font-family: "Poppins", sans-serif;
  color: #00364f;
  text-decoration: none;
}

div.scrollmenu a .card {
  height: 50%;
  width: 40%;
}

.come-from-right .modal-dialog {
  position: fixed;
  top: 0px;
  right: 0px;
  margin: auto;
  width: 300px;
  height: 100%;
  transform: translate3d(0%, 0, 0);
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.come-from-right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0px;
}

.come-from-right .modal-body {
  padding: 15px 15px 80px;
}
.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -26px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.bg-light-success[data-v-5ace4cc6] {
  background: #64d50f59 !important;
}
.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
h4[data-v-69e5a8da] {
  color: #fff;
  font-family: poppins, Sans-serif;
  font-weight: 600;
  font-size: 25;
}
input:checked {
  /* background-color: #7367f0; */
  border-color: #ff000c;
  border-width: 3px;
}
.form-check-input:checked {
  background-color: #fdfdfd;
  /* border-color: #7367f0; */
}
</style>
